import React from 'react';

import { PROGRAM_DATA_EN, PROGRAM_DATA_SK } from '../../../constants/2022/program';

import './program.scss';

export const Program: React.FC<{ language: string }> = ({ language }) => {
  const programData = language === 'en' ? PROGRAM_DATA_EN : PROGRAM_DATA_SK;

  return (
    <div className="Program" id="cs-program">
      <h1 className="Program-header">{programData.header}</h1>
      <div className="Program-container">
        <table>
          {programData.timeSlots.map((data) => (
            <tr className="Program-slot">
              <td className="Program-interval">{data.interval}</td>
              <td className="Program-description">{data.description}</td>
            </tr>
          ))}
        </table>
        <div className="Program-announcementContainer">
          <p>
            {/* {language === 'en'
              ? 'More information coming soon...'
              : 'Viac informácií už čoskoro...'} */}
              {programData.endNote}
          </p>
        </div>
      </div>
    </div>
  );
};
