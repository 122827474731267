import React from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { SummitPage2021 } from '../pages/summit-page-2021';
import { SummitPage2022 } from '../pages/summit-page-2022';
import { SummitPage2023 } from '../pages/summit-page-2023';
import { SummitPage2024 } from '../pages/summit-page-2024';

const RemoveTrailingSlash: React.FC<{ children: any }> = ({ children }) => {
  const location = useLocation();

  if (location.pathname !== '/' && location.pathname.endsWith('/')) {
    return <Navigate to={location.pathname.slice(0, -1) + location.search} />;
  }

  return children;
};

const Router: React.FC<{ markedLanguage: string; onLanguageChange: any }> = ({
  markedLanguage,
  onLanguageChange,
}) => {
  return (
    <RemoveTrailingSlash>
      <Routes>
        <Route
          path="/"
          element={
            <SummitPage2024 markedLanguage={markedLanguage} onLanguageChange={onLanguageChange} />
          }
        />
        <Route
          path="/2023"
          element={
            <SummitPage2023 markedLanguage={markedLanguage} onLanguageChange={onLanguageChange} />
          }
        />
        <Route
          path="/2022"
          element={
            <SummitPage2022 markedLanguage={markedLanguage} onLanguageChange={onLanguageChange} />
          }
        />
        <Route
          path="/2021"
          element={
            <SummitPage2021 markedLanguage={markedLanguage} onLanguageChange={onLanguageChange} />
          }
        />
      </Routes>
    </RemoveTrailingSlash>
  );
};

export default Router;
