import React from 'react';
import { GOALS_DATA_EN, GOALS_DATA_SK, GoalData } from '../../../constants/2022/goals';
import { GoalCard } from './goal-card';

import './goals.scss';

export const Goals: React.FC<{ language: string }> = ({ language }) => {
  const goalData = language === 'en' ? GOALS_DATA_EN : GOALS_DATA_SK;

  return (
    <div className="Goals" id="cs-goals">
      <div className="Goals-contentContainer">
      <h1 className="Goals-header">{goalData.header}</h1>
        <div className="Goals-content">
          <div className="Goals-list">
            {goalData.data.map((goal: GoalData) => (
              <GoalCard goal={goal} />
            ))}
          </div>
          
        </div>
      </div>
    </div>
  );
};
