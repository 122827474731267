import React from 'react';
import { Speaker } from '../../../constants/2022/speakers';

import './personal-card.scss';

export const PersonalCard23: React.FC<{ speaker: Speaker; language: string }> = ({
  speaker,
  language,
}) => {
  return (
    <div className="PersonalCard23">
      <div className="PersonalCard23-header">
        <img
          className="PersonalCard23-image"
          alt={speaker.name}
          src={process.env.PUBLIC_URL + speaker.image}
        />
        <div className="PersonalCard23-nameContainer">
          <div className="PersonalCard23-name" data-text={speaker.name}>
            <span>{speaker.name}</span>
          </div>
        </div>
      </div>
      <div className="PersonalCard23-text">
        <span>{language === 'en' ? speaker.textEN : speaker.textSK}</span>
      </div>
    </div>
  );
};
