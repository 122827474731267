import { MenuData } from "../../types/menu";

export const MENU_DATA: MenuData = {
  menuButtons: [
    {
      nameSK: 'Úvod',
      nameEN: 'Introduction',
      link: 'cs-introduction',
    },
    {
      nameSK: 'Ciele',
      nameEN: 'Goals',
      link: 'cs-goals',
    },
    // {
    //   nameSK: 'Program',
    //   nameEN: 'Program',
    //   link: 'cs-program',
    // },
    // {
    //   nameSK: 'Rečníci',
    //   nameEN: 'Speakers',
    //   link: 'cs-speakers',
    // },
    // {
    //   nameSK: 'Témy',
    //   nameEN: 'Themes',
    //   link: 'cs-themes',
    // },
    // {
    //   nameSK: 'Registrácia',
    //   nameEN: 'Registration',
    //   link: 'cs-registration',
    // },
    {
      nameSK: 'Galéria',
      nameEN: 'Gallery',
      link: 'cs-gallery',
    },
    // {
    //   nameSK: 'Partneri',
    //   nameEN: 'Partners',
    //   link: 'cs-sponsors',
    // },
    {
      nameSK: 'Organizátori',
      nameEN: 'Organizers',
      link: 'cs-about-us',
    },
    {
      nameSK: 'Kontakt',
      nameEN: 'Contact',
      link: 'cs-contact',
    },
  ],
};
