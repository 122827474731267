import React from 'react';

import { PROGRAM_DATA } from '../../../constants/2021/program';

import './program-21.scss';

export const Program21: React.FC<{ language: string }> = ({ language }) => {
  return (
    <div className="Program21" id="cs-program">
      <h1 className="Program21-header">
        {language === 'en' ? PROGRAM_DATA.headerEN : PROGRAM_DATA.headerSK}
      </h1>
      <div className="Program21-container">
        <table>
          {PROGRAM_DATA.timeSlots.map((data) => (
            <tr className="Program21-slot">
              <td className="Program21-interval">
                {language === 'en' ? data.intervalEN : data.intervalSK}
              </td>
              <td className="Program21-description">
                {language === 'en' ? data.descriptionEN : data.descriptionSK}
              </td>
            </tr>
          ))}
        </table>
        <div className="Program21-announcementContainer">
          <p>
            {/* {language === 'en'
              ? 'More information coming soon...'
              : 'Viac informácií už čoskoro...'} */}
            {language === 'en' ? PROGRAM_DATA.endNoteEN : PROGRAM_DATA.endNoteSK}
          </p>
        </div>
      </div>
    </div>
  );
};
