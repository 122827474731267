import React from 'react';

import './sponsors.scss';
import { SPONSORS23 } from '../../../constants/2023/sponsors';

export const Sponsors23: React.FC<{ language: string }> = ({ language }) => {
  return (
    <div className="Sponsors23" id="cs-sponsors">
      <h1 className="Sponsors23-header">{language === 'en' ? 'Partners' : 'Partneri'}</h1>
      <div className="Sponsors23-content">
        {SPONSORS23.map((sponsor) => (
          <a href={sponsor.link}>
            <img className="Sponsors23-sponsorLogo" alt={sponsor.name} src={sponsor.image} />
          </a>
        ))}
      </div>
    </div>
  );
};
