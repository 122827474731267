/* eslint-disable react/style-prop-object */
import React from 'react';

import { REGISTRATION_DATA_EN, REGISTRATION_DATA_SK } from '../../../constants/2021/registration';
// import { RegistrationButton } from '../registration-button/registration-button';

import './registration-21.scss';

export const Registration21: React.FC<{ language: string }> = ({ language }) => {
  const registrationData = language === 'en' ? REGISTRATION_DATA_EN : REGISTRATION_DATA_SK;

  const registrationText =
    language === 'en' ? (
      <>
        Registration and attendance at the Summit is by invitation only.
        <br />
        <br />
        Invitation to the event includes all Summit panels, as well as lunch, the evening reception and refreshments throughout the day.
        <br />
        <br />
        If you would like to attend the event but do not have an invitation, you can email us at:
      </>
    ) : (
      <>
        Registrácia a prezenčný vstup na summit je len na pozvánky.
        <br />
        <br />
        Pozvanie na podujatie zahŕňa všetky panely summitu, ako aj obed, večernú recepciu a občerstvenie v priebehu dňa.
        <br />
        <br />
        Pokiaľ by ste sa podujatia radi zúčastnili, ale nedisponujete pozvánkou, môžete nám napísať na:
      </>
    );

  return (
    <div className="Registration21" id="cs-registration">
      <h1 className="Registration21-header">{registrationData.header}</h1>
      <div className="Registration21-container">
        <h3>{registrationData.date.header}</h3>
        <div className="Registration21-text">{registrationData.date.text}</div>
        <h3>{registrationData.locality.header}</h3>
        <div className="Registration21-text">{registrationData.locality.text}</div>
        <div className="Registration21-mapIconContainer">
          <a
            href="https://www.google.com/maps/dir//48.1458101,17.1060862/@48.1456453,17.0358674,12z/data=!3m1!4b1!4m2!4m1!3e2"
            className="Registration21-iconAnchor"
          >
            <i className="material-icons">directions_walk</i>
          </a>
          <a
            href="https://www.google.com/maps/dir//48.1458101,17.1060862/@48.1456453,17.0358682,12z/data=!4m2!4m1!3e0"
            className="Registration21-iconAnchor"
          >
            <i className="material-icons">directions_car</i>
          </a>
          <a
            href="https://www.google.com/maps/dir//48.1458101,17.1060862/@48.1456453,17.0358682,12z/data=!3m1!4b1!4m2!4m1!3e3"
            className="Registration21-iconAnchor"
          >
            <i className="material-icons">directions_bus</i>
          </a>
          <a
            href="https://www.google.com/maps/dir//48.1458101,17.1060862/@48.1456453,17.0358682,12z/data=!3m1!4b1!4m2!4m1!3e1"
            className="Registration21-iconAnchor"
          >
            <i className="material-icons">directions_bike</i>
          </a>
        </div>
        <div className="Registration21-mapContainer">
          <iframe
            className="Registration21-map"
            title="google-map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2662.155169212195!2d17.103897515834834!3d48.14581365856593!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476c895c9027b8c3%3A0xe95407545fcd38e6!2sHistorick%C3%A1%20budova%20N%C3%A1rodnej%20rady%20SR!5e0!3m2!1sde!2ssk!4v1662334057385!5m2!1sde!2ssk"
            allowFullScreen={true}
            loading="lazy"
          ></iframe>
        </div>

        <h3>{registrationData.registration.header}</h3>
        <div className="Registration21-registrationText">{registrationText}</div>
        <a className="Registration21-announcementContainer" href="mailto:info@konzervativnysummit.sk">
          info@konzervativnysummit.sk
        </a>
        {/* <div className="Registration-pulsation">
          <RegistrationButton>{registrationData.registration.buttonText}</RegistrationButton>
        </div> */}
        <h3>{registrationData.dressCode.header}</h3>
        <div className="Registration21-text">{registrationData.dressCode.text}</div>
      </div>
    </div>
  );
};
