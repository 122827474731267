import { Header } from '../components/UI/header/header';
import { Speakers21 } from '../components/2021/speakers-21/speakers-21';
import { Intro } from '../components/2022/introduction/intro';
import { Sponsors21 } from '../components/2021/sponsors-21/sponsors-21';
import { Program21 } from '../components/2021/program-21/program-21';
import { Contact } from '../components/UI/contact/contact';
import { Registration21 } from '../components/2021/registration-21/registration-21';
import { Organisers21 } from '../components/2021/organisers-21/organisers-21';
import { Goals } from '../components/2022/goals/goals';
// import { Themes } from '../components/2022/themes-2022/themes';
import { Gallery } from '../components/UI/gallery/gallery';
import {
  GALLERY_IMAGES,
  GALLERY_VIDEOS,
  GALLERY_DATA_EN,
  GALLERY_DATA_SK,
} from '../constants/2021/gallery';
import { MENU_DATA } from '../constants/2021/menu';

export const SummitPage2021: React.FC<{ markedLanguage: string; onLanguageChange: any }> = ({
  markedLanguage,
  onLanguageChange,
}) => {
  return (
    <>
      <Header
        language={markedLanguage}
        onLanguageChange={onLanguageChange}
        site="2021"
        menuData={MENU_DATA}
        showSloganSlide={false}
      />
      <Intro language={markedLanguage} />
      <Goals language={markedLanguage} />
      <Program21 language={markedLanguage} />
      <Speakers21 language={markedLanguage} />
      {/* <Themes language={markedLanguage} /> */}
      <Registration21 language={markedLanguage} />
      <Gallery
        language={markedLanguage}
        images={GALLERY_IMAGES}
        videos={GALLERY_VIDEOS}
        data={markedLanguage === 'en' ? GALLERY_DATA_EN : GALLERY_DATA_SK}
      />
      <Sponsors21 language={markedLanguage} />
      <Organisers21 language={markedLanguage} />
      <Contact language={markedLanguage} />
    </>
  );
};
