import React from 'react';
import classNames from 'classnames';
import { SiteSwitchContent } from '../site-switch/site-switch';

import './site-switch-button.scss';
import { Link } from 'react-scroll';

type BackgroundUsage = 'dark' | 'bright';

export const SiteSwitchButton: React.FC<{
  backgroundUsage: BackgroundUsage;
  language: string;
  switchLinks: SiteSwitchContent;
}> = ({ backgroundUsage, language, switchLinks }) => {
  const allowedClickLinks = ['2024', '2023', '2022', '2021'];
  const actualLink = window.location.pathname;

  return (
    <div className="SiteSwitchButton">
      <div className="SiteSwitchButton-header">{language === 'en' ? 'Year:' : 'Ročník:'}</div>
      {switchLinks.map((link, index) => {
        return (
          <Link
            key={index}
            className={classNames('SiteSwitchButton-linkButton', {
              'SiteSwitchButton-linkButton--actual': link.link === actualLink,
              'SiteSwitchButton-linkButton--clickable':
                allowedClickLinks.includes(link.name) && link.link !== actualLink,
            })}
            to={link.link}
            onClick={() =>
              allowedClickLinks.includes(link.name)
                ? (window.location.pathname = `${link.link}`)
                : null
            }
          >
            {link.name}
          </Link>
        );
      })}
    </div>
  );
};
