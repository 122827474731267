import React from 'react';
import { INTRO_DATA_EN, INTRO_DATA_SK } from '../../../constants/2023/intro';

import './intro-23.scss';

export const Intro23: React.FC<{ language: string }> = ({ language }) => {
  const introData = language === 'en' ? INTRO_DATA_EN : INTRO_DATA_SK;

  const intentionText =
    language === 'en' ? (
      <>
        St. John Paul II. in 1996, during a visit to Slovakia, said:{' '}
        <i>
          "Slovakia has a great role to play in the building of the Europe of the third millennium.
          Remember that! It is called to offer its very significant contribution to the true
          progress of the European continent through its traditions, its culture, its martyrs and
          confessors, as well as through the living forces of its new generations."
        </i>
        <br />
        <br />
        Based on these words, as well as the words of several other Christian personalities, we
        believe that our nation, inconspicuous on a European scale, can contribute in an important
        way to saving the identity of the Western world.
        <br />
        <br />
        The Conservative Summit is a meeting of Christian and conservative leaders and personalities
        from politics, culture, churches, civil society, academia, business and the media. Its
        intention is to facilitate greater sensitivity to current challenges in public life, as well
        as preparation for the struggles that lie ahead.
        <br />
        <br />
        The conservative summit has a non-partisan character and is a unique opportunity to build
        good relations and an atmosphere of cooperation in the conservative spectrum.
      </>
    ) : (
      <>
        Sv. Ján Pavol II. v roku 1996 pri návšteve Slovenska povedal:{' '}
        <i>
          "Slovensko má veľkú úlohu pri budovaní Európy tretieho tisícročia. Dobre si to uvedomte!
          Je povolané ponúknuť svoj veľmi významný príspevok k pravému pokroku európskeho kontinentu
          svojimi tradíciami, kultúrou, svojimi mučeníkmi a vyznávačmi, ako aj živými silami svojich
          nových generácií."
        </i>
        <br />
        <br />
        Na základe týchto slov, ale i slov viacerých ďalších kresťanských osobností veríme, že náš,
        v európskom meradle nenápadný národ, môže dôležitým spôsobom prispieť k záchrane identity
        západného sveta.
        <br />
        <br />
        Konzervatívny summit je stretnutie kresťanských a konzervatívnych lídrov a osobností z
        politiky, kultúry, cirkví, občianskej spoločnosti, akadémie, biznisu a médií. Jeho zámerom
        je napomôcť väčšej vnímavosti na súčasné výzvy vo verejnom živote, a tiež príprave na
        zápasy, ktoré pred nami stoja.
        <br />
        <br />
        Konzervatívny summit má nadstranícky charakter a je jedinečnou príležitosťou na budovanie
        dobrých vzťahov a atmosféry spolupráce v konzervatívnom spektre.
      </>
    );

  return (
    <div className="Intro23" id="cs-introduction">
      <img className="Intro23-image" alt="intro" src="./krivan.jpg" />
      <div className="Intro23-contentContainer">
        <div className="Intro23-content">
          <div className="Intro23-intention">
            <h1 className="Intro23-header">{introData.header}</h1>
            <div className="Intro23-text">
              <p>{intentionText}</p>
            </div>
          </div>
          {/* <h1>{introData.goals.header}</h1>
          <div className="Intro23-goals">
            {introData.goals.data.map((goal: GoalData) => (
              <GoalCard goal={goal} />
            ))}
          </div>
          <h3>{introData.themes.header}</h3>
          <div className="Intro23-themes">
            {introData.themes.data.map((theme: ThemeData) => (
              <ThemeBubble theme={theme} />
            ))}
          </div> */}
        </div>
      </div>
    </div>
  );
};
