import React from 'react';
import { COUNT_DOWN_DATA_EN, COUNT_DOWN_DATA_SK } from '../../../constants/2022/count-down-data';
import { RegistrationButton } from '../../2022/registration-button/registration-button';

import './count-down.scss';

export const CountDown: React.FC<{ countDown?: number[]; language: string }> = ({
  countDown,
  language,
}) => {
  const [days, hours, minutes, seconds] = countDown || [0, 0, 0, 0];
  const csData = language === 'en' ? COUNT_DOWN_DATA_EN : COUNT_DOWN_DATA_SK;

  return (
    <div className="CountDown">
      <h1 className="CountDown-header">{csData.header.text}</h1>
      <div className="CountDown-container">
        <div className="CountDown-valueContainer">
          <div className="CountDown-value">{days}</div>
          <div className="CountDown-valueText">{csData.time.cdDay.text}</div>
        </div>
        <div className="CountDown-valueContainer">
          <div className="CountDown-value">{hours}</div>
          <div className="CountDown-valueText">{csData.time.cdHour.text}</div>
        </div>
        <div className="CountDown-valueContainer">
          <div className="CountDown-value">{minutes}</div>
          <div className="CountDown-valueText">{csData.time.cdMinute.text}</div>
        </div>
        <div className="CountDown-valueContainer">
          <div className="CountDown-value">{seconds}</div>
          <div className="CountDown-valueText">{csData.time.cdSecond.text}</div>
        </div>
      </div>
      {/* <div className="CountDown-pulsation">
        <RegistrationButton isOnTop={true}>
          {language === 'en' ? 'Register here!' : 'Zaregistrujte sa tu!'}
        </RegistrationButton>
      </div> */}
    </div>
  );
};
