import { Header } from '../components/UI/header//header';
import { Speakers } from './../components/2022/speakers/speakers';
import { Intro } from '../components/2022/introduction/intro';
import { Sponsors } from './../components/2022/sponsors/sponsors';
import { Program } from './../components/2022/program/program';
import { Contact } from './../components/UI/contact/contact';
import { Registration } from './../components/2022/registration/registration';
import { Organisers } from '../components/UI/organisers/organisers';
import { Goals } from './../components/2022/goals/goals';
import { Themes } from './../components/2022/themes-2022/themes';
import { Gallery } from '../components/UI/gallery/gallery';
import {
  GALLERY_IMAGES,
  GALLERY_VIDEOS,
  GALLERY_DATA_EN,
  GALLERY_DATA_SK,
} from '../constants/2022/gallery';
import { MENU_DATA } from '../constants/2023/menu';

export const SummitPage2022: React.FC<{ markedLanguage: string; onLanguageChange: any }> = ({
  markedLanguage,
  onLanguageChange,
}) => {
  return (
    <>
      <Header
        language={markedLanguage}
        onLanguageChange={onLanguageChange}
        site="2022"
        menuData={MENU_DATA}
        showSloganSlide={false}
      />
      <Intro language={markedLanguage} />
      <Goals language={markedLanguage} />
      <Program language={markedLanguage} />
      <Speakers language={markedLanguage} />
      <Themes language={markedLanguage} />
      <Registration language={markedLanguage} />
      <Gallery
        language={markedLanguage}
        // showImage
        // showVideo
        images={GALLERY_IMAGES}
        videos={GALLERY_VIDEOS}
        data={markedLanguage === 'en' ? GALLERY_DATA_EN : GALLERY_DATA_SK}
      />
      <Sponsors language={markedLanguage} />
      <Organisers language={markedLanguage} showCoorganizers />
      <Contact language={markedLanguage} />
    </>
  );
};
