import React from 'react';
import { ReactComponent as FacebookIcon } from './facebook.svg';
import { ReactComponent as YoutubeIcon } from './youtube.svg';
// import { ReactComponent as TwitterIcon } from './twitter.svg';

import { CONTACT_DATA_EN, CONTACT_DATA_SK } from '../../../constants/2022/contact';

import './contact.scss';

export const Contact: React.FC<{ language: string }> = ({ language }) => {
  const contactData = language === 'en' ? CONTACT_DATA_EN : CONTACT_DATA_SK;

  return (
    <div className="Contact" id="cs-contact">
      <h1 className="Contact-header">{contactData.header.text}</h1>
      <a className="Contact-announcementContainer" href="mailto:info@konzervativnysummit.sk">
        info@konzervativnysummit.sk
      </a>
      <div className="Contact-socialMediaContainer">
        <a href="https://www.facebook.com/konzervativnysummit/" >
          <FacebookIcon className="Contact-socialMediaLogo" />
        </a>
        <a href="https://www.youtube.com/@konzervativnysummit/" >
          <YoutubeIcon className="Contact-socialMediaLogo" />
        </a>
        {/* <a href="" >
          <TwitterIcon className="Contact-socialMediaLogo" />
        </a> */}
      </div>
    </div>
  );
};
