import React from 'react';

import './site-switch.scss';
import { SiteSwitchButton } from '../site-switch-button/site-switch-button';

export type SiteSwitchEntry = {
  name: string;
  link: string;
};

export type SiteSwitchContent = SiteSwitchEntry[];

export const SiteSwitch: React.FC<{ language: string; site: string }> = ({ language, site }) => {
  const switchLinks: SiteSwitchContent = [
    {
      name: '2024',
      link: '/',
    },
    {
      name: '2023',
      link: '/2023',
    },
    {
      name: '2022',
      link: '/2022',
    },
    {
      name: '2021',
      link: '/2021',
    },
  ];

  return (
    <div className="SiteSwitch">
      <SiteSwitchButton backgroundUsage="dark" language={language} switchLinks={switchLinks} />
    </div>
  );
};
