import { Header } from '../components/UI/header/header';
// import { Speakers } from '../components/2023/speakers/speakers';
import { Intro24 } from '../components/2024/introduction-24/intro-24';
import { CountDown } from '../components/2023/count-down/count-down';
// import { Advertisement } from './../components/advertisement/advertisement';
import { Gallery24 } from '../components/2024/gallery-24/gallery-24';
// import { Program23 } from '../components/2023/program-23/program-23';
import { Contact } from '../components/UI/contact/contact';
import { Registration } from '../components/2024/registration/registration';
import { Organisers } from '../components/UI/organisers/organisers';
import { useCountdown } from '../hooks/use-count-down';
import { Goals } from '../components/2023/goals/goals';
// import { Sponsors23 } from '../components/2023/sponsors/sponsors';
// import { Themes23 } from '../components/2023/themes-23/themes-23';
import { MENU_DATA } from '../constants/2024/menu';
import {
  GALLERY_IMAGES,
  // GALLERY_VIDEOS,
  GALLERY_DATA_EN,
  GALLERY_DATA_SK,
} from '../constants/2024/gallery';

export const SummitPage2024: React.FC<{ markedLanguage: string; onLanguageChange: any }> = ({
  markedLanguage,
  onLanguageChange,
}) => {
  const COUNT_DOWN_FINISH = '2024-11-11';
  const myCountdown = useCountdown(COUNT_DOWN_FINISH);

  // const hasFinalParam = window.location.href.includes('showFinalCSSite=true');
  return (
    <>
      <Header
        language={markedLanguage}
        onLanguageChange={onLanguageChange}
        site="2024"
        menuData={MENU_DATA}
        showSloganSlide
      />
      <CountDown countDown={myCountdown} language={markedLanguage} />
      <Registration language={markedLanguage} />
      <Intro24 language={markedLanguage} />
      <Goals language={markedLanguage} />
      {/* <Program23 language={markedLanguage} /> */}
      {/* <Speakers language={markedLanguage} /> */}
      {/* <Themes23 language={markedLanguage} /> */}
      <Gallery24
        language={markedLanguage}
        // showImage
        // showVideo={false}
        images={GALLERY_IMAGES}
        // videos={}
        data={markedLanguage === 'en' ? GALLERY_DATA_EN : GALLERY_DATA_SK}
      />
      {/* <Sponsors23 language={markedLanguage} /> */}
      <Organisers language={markedLanguage} showCoorganizers={false} />
      <Contact language={markedLanguage} />
    </>
  );
};
