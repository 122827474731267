import React from 'react';
import { Link } from 'react-scroll'; 

import './menu-button.scss';

export type ButtonProp = {
  name: string;
  link: string;
  onClick?: any;
  smoothScrolling: boolean;
  isMenuFixed: boolean;
};

export const MenuButton: React.FC<ButtonProp> = ({ name, link, onClick, smoothScrolling, isMenuFixed }) => {
  return (
    <li className="MenuButton-listElement">
      <Link className="MenuButton-link" to={link} smooth={smoothScrolling} offset={isMenuFixed ? -160 : -230} onClick={onClick}>
        {name}
      </Link>
    </li>
  );
};
