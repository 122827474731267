import { Header } from '../components/UI/header/header';
import { Speakers } from '../components/2023/speakers/speakers';
import { Intro23 } from '../components/2023/introduction-23/intro-23';
import { Gallery } from '../components/UI/gallery/gallery';
import { Program23 } from '../components/2023/program-23/program-23';
import { Contact } from '../components/UI/contact/contact';
import { Registration } from '../components/2023/registration/registration';
import { Organisers } from '../components/UI/organisers/organisers';
import { Goals } from '../components/2023/goals/goals';
import { Sponsors23 } from '../components/2023/sponsors/sponsors';
import { Themes23 } from '../components/2023/themes-23/themes-23';
import { MENU_DATA } from '../constants/2023/menu';
import {
  GALLERY_IMAGES,
  GALLERY_VIDEOS,
  GALLERY_DATA_EN,
  GALLERY_DATA_SK,
} from '../constants/2023/gallery';

export const SummitPage2023: React.FC<{ markedLanguage: string; onLanguageChange: any }> = ({
  markedLanguage,
  onLanguageChange,
}) => {
  return (
    <>
      <Header
        language={markedLanguage}
        onLanguageChange={onLanguageChange}
        site="2023"
        menuData={MENU_DATA}
        showSloganSlide
      />
      <Intro23 language={markedLanguage} />
      <Goals language={markedLanguage} />
      <Program23 language={markedLanguage} />
      <Speakers language={markedLanguage} />
      <Themes23 language={markedLanguage} />
      <Registration language={markedLanguage} />
      <Gallery
        language={markedLanguage}
        // showImage
        // showVideo
        images={GALLERY_IMAGES}
        videos={GALLERY_VIDEOS}
        data={markedLanguage === 'en' ? GALLERY_DATA_EN : GALLERY_DATA_SK}
      />
      <Sponsors23 language={markedLanguage} />
      <Organisers language={markedLanguage} showCoorganizers={false} />
      <Contact language={markedLanguage} />
    </>
  );
};
