import React from 'react';

import './speakers-21.scss';
import { SPEAKER_DATA } from '../../../constants/2021/speakers';
import { PersonalCard } from '../personal-card/personal-card';

export const Speakers21: React.FC<{ language: string }> = ({ language }) => {
  return (
    <div className="Speakers21" id="cs-speakers">
      <h1 className="Speakers21-header">
        {language === 'en' ? SPEAKER_DATA.headerEN : SPEAKER_DATA.headerSK}
      </h1>
      <div className="Speakers21-cards">
        {SPEAKER_DATA.speakers.map((speaker) => (
          <PersonalCard speaker={speaker} language={language} />
        ))}
      </div>
      <div className="Speakers21-announcement">
        {language === 'en'
          ? 'and more...'
          : 'a ďalší...'}
      </div>
    </div>
  );
};
